let autocomplete = {};

export const initPlaces = (id, callback) => {
  if (typeof google !== 'undefined') {
    // eslint-disable-next-line no-undef
    autocomplete[id] = new google.maps.places.Autocomplete(
      document.getElementById(id)
    );

    autocomplete[id].addListener("place_changed", () => {
      const place = autocomplete[id].getPlace();
      let zoomLevel = 15;
      console.log(place)
      if (place.types.includes("locality")) {
        zoomLevel = 12; 
      } else if (place.types.includes("country")) {
        zoomLevel = 5; 
      } else if (place.types.includes("street_address")) {
        zoomLevel = 17;
      }else if (place.types.includes("continent")) {
        zoomLevel = 2; 
      }

      return callback({
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
        destination: place.formatted_address,
        zoom: zoomLevel
      });
    });
  } else {
    console.error("Google Maps API is not loaded.");
  }
};

export const removeInstance = () => {
  if (autocomplete) {
    Object.values(autocomplete).forEach((v) => {
      // eslint-disable-next-line no-undef
      google.maps.event.clearInstanceListeners(v);
    });
  }
};